import React from "react";
import $ from "./about.module.scss";

export const About = () => {
  return (
    <section className={$.section_about} id="about_section">
      <div className={$.section_about_container}>
        <div className={$.section_about_container_header}>
          <h1>
            Who We Are <span>?</span>
          </h1>
        </div>
        <div className={$.section_about_container_content_wrapper}>
          <div
            className={$.section_about_container_content_wrapper_img_wrapper}
          >
            <img src="/canada.png" alt="Canadian web agency" />
          </div>
          <div
            className={$.section_about_container_content_wrapper_text_wrapper}
          >
            <p>
              Founded in 2020, our Canada-based company assists businesses in
              enhancing their outcomes and scaling their online presence. We
              specialize in automating business processes to improve efficiency,
              eliminate routine tasks, and reduce errors. Our services ensure
              that your online presence saves time for you, your clients, and
              future partners.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
