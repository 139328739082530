import React from "react";
import $ from "./process.module.scss";

export const Process = ({ scrollToSection }) => {
  const cards = [
    {
      num: 1,
      desc: "Fill out the contact form, and we will be in touch with you.",
      action: () => scrollToSection({ section: "contact_section" }),
      btnName: "Let's start",
    },
    {
      num: 2,
      desc: "Arrange a call session with us to find out the best solution for your idea.",
    },
    {
      num: 3,
      desc: "We will send you a project quote including the full cost and delivery time.",
    },
    {
      num: 4,
      desc: "We develop, following the discussed plan, test, and launch your project on the web.",
    },
  ];

  return (
    <section className={$.process_section} id="process_section">
      <div className={$.process_section_header}>
        <h1>
          How We Work
        </h1>
      </div>
      <div className={$.process_section_card_wrapper}>
        {cards.map((item, index) => (
          <div className={$.process_card} key={index}>
            <div className={$.process_card_number}>
              <span>{item.num}</span>
            </div>
            <div>
              <p>{item.desc}</p>
            </div>
            {item.action && (
              <div>
                <button
                  className={$.process_card_button}
                  onClick={() => item.action()}
                >
                  let’s start
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};
