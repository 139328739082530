import React, { useEffect, useRef, useState } from "react";
import $ from "./mainSection.module.scss";
import useIsMobile from "../../hooks/useIsMobile";
import * as THREE from "three";

export const MainSection = ({ scrollToSection }) => {
  const myRef = useRef();

  useEffect(() => {
    let canvas = document.createElement("canvas", {
      ref: myRef,
      id: "bg",
    });
    canvas.classList.add(`${$.canvas_container}`);
    canvas.id = "bg";
    let container = document.getElementById("container");
    container.appendChild(canvas);
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      113,
     250/ 500,
      1,
      100
    );

    const render = new THREE.WebGL1Renderer({
      canvas: document.querySelector(" #bg"),
      alpha: true,
    });
    render.setPixelRatio(window.devicePixelRatio);
    render.setSize(canvas.clientHeight, canvas.clientWidth);
    camera.position.setZ(35);
    render.render(scene, camera);

    const earthTexture = new THREE.TextureLoader().load("/earth.jpg");
    const earth = new THREE.SphereGeometry(20, 32, 16);
    // const material = new THREE.MeshStandardMaterial({
    //   map: earthTexture,
    // });
    const tours = new THREE.Mesh(
      earth,
      new THREE.MeshStandardMaterial({ map: earthTexture })
    );
    scene.add(tours);

    const pointLight = new THREE.PointLight(0xffffff, 100);
    pointLight.position.set(10, 10, 10);
    const ambientLight = new THREE.AmbientLight(0xffffff, 3);

    scene.add(pointLight, ambientLight);
    function animate() {
      requestAnimationFrame(animate);
      // tours.rotation.x += 0.01;
      tours.rotation.y += 0.003;
      // tours.rotation.z += 0.01;
      render.render(scene, camera);
    }

    animate();

    // function addStart() {
    //   const geometry = new THREE.SphereGeometry(0.01, 300, 204);
    //   const material = new THREE.MeshStandardMaterial({ color: 0xffffff });
    //   const star = new THREE.Mesh(geometry, material);
    //   const [x, y, z] = Array(3)
    //     .fill()
    //     .map(() => THREE.MathUtils.randFloatSpread(100));
    //   star.position.set(x, y, z);
    //   scene.add(star);
    // }

    // Array(200).fill().forEach(addStart);

    // function moveCamera() {
    //   let cur = document.body.getBoundingClientRect().top;
    //   earth.rotateX += 0.2;
    //   earth.rotateY += 0.2;
    //   earth.rotateZ += 0.2;
    //   console.log(cur);
    //   camera.position.setZ(30);
    // }

    // document.body.onscroll = moveCamera();

    return () => {
      container.removeChild(canvas);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useIsMobile();
  const menu_btn = useRef(null);
  const curtain_menu = useRef(null);

  const changeClass = () => {
    if (isMobile) {
      if (!isMenuOpen && menu_btn.current) {
        curtain_menu.current.style.width = "100%";
        menu_btn.current.classList.toggle($.change);
        setIsMenuOpen(true);
      } else {
        menu_btn.current.classList.toggle($.change);
        curtain_menu.current.style.width = "0";
        setIsMenuOpen(false);
      }
    }
  };

  const onclickScrollToSection = ({ section }) => {
    changeClass();
    scrollToSection({ section });
  };

  return (
    <>
      <header className={$.main_section_header}>
        <div className={$.main_section_header_logo}>
          Filin Development <span>.</span>
        </div>
        <div className={$.main_section_header_menu} ref={curtain_menu}>
          <ul>
            <li
              onClick={() =>
                onclickScrollToSection({ section: "services_section" })
              }
            >
              Services
            </li>
            <li
              onClick={() =>
                onclickScrollToSection({ section: "process_section" })
              }
            >
              Process
            </li>
            <li
              onClick={() =>
                onclickScrollToSection({ section: "about_section" })
              }
            >
              About
            </li>
            <li
              onClick={() =>
                onclickScrollToSection({ section: "contact_section" })
              }
              className={$.contact_btn}
            >
              Contact<span></span>
            </li>
          </ul>
        </div>
        <div
          className={$.burger_menu_closed}
          ref={menu_btn}
          onClick={() => changeClass()}
        >
          <div className={$.bar1}></div>
          <div className={$.bar2}></div>
          <div className={$.bar3}></div>
        </div>
      </header>
      <section className={$.main_section} id="main_section">
        <div className={$.main_section_text_wrapper}>
          <div className={$.main_section_text_wrapper_container}>
            <h1>
              Full-service digital agency
              <br />
            </h1>
            <h4>
              <mark style={{ backgroundColor: "#66ac82 !important" }}>
                Driven by Passion, Defined by Growth, Dedicated to Your Future.
              </mark>
            </h4>
          </div>
          <div className="container" id="container"></div>
        </div>
      </section>
    </>
  );
};
